
.mine-layout {
  background: #ffffff;
  .mine-auth {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .mine-header {
    background: linear-gradient(180deg, #ededed 0%, #ffffff 100%);
    .header-info {
      width: 348px;
      height: 131px;
      background: url(../../assets/image/mine/bg.png);
      background-size: 348px 131px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin: 0 auto;
      .info-lt {
        width: 48px;
        height: 48px;
        margin-left: 30px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .info-rt {
        color: #ffffff;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 16px;
        .name {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
        }
        .mobile {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .img-bg02 {
        width: 100%;
        height: 51px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
  .mine-content {
    padding: 0 16px 0 20px;
    .options-list {
      padding-top: 23px;
      .option-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding: 13px 0;
        .item-info {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #333;
          .incon {
            padding-right: 16px;
          }
          .icon-mine {
            width: 24px;
            height: 24px;
            padding-right: 15px;
          }
        }
        .between {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 9px;
          .icon-new {
            width: 34px;
            height: 16px;
            background: url('../../assets/image/mine/icon-new.png') no-repeat 0
              0;
            background-size: contain;
          }
          .reviced {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
}
