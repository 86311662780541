
.van-dialog__header--isolated{
    padding:10px !important;
}
.page-address {
	height: 100%;
	background: #F9F9F9;
    font-size: 14px;
	.item{
		border-radius: 8px;
		margin: 12px 15px 0 15px;
        background: #ffffff;
        padding: 15px 15px 0 15px;
        .item-info{
            border-bottom: 1px solid #E6E6E6;
            .title{
                text-align: start;
            }
            .info-bt{
                color: #666666;
                line-height: 20px;
                margin: 8px 0 12px 0;
                span{
                    padding-right: 10px;
                }
            }
        }
        .item-method{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 43px;
            color: #666666;
            .method-lt{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .icon{
                    width: 14px;
                    height: 14px;
                    margin-right: 2px;
                }
            }
            .method-rt{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .rt-item{
                    margin-left: 16px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .icon{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

	}
    /deep/ .van-cell{
		padding: 0 !important;
		background: none !important;
        border: none !important;
	}
    .big-btn{
        position: fixed;
        left: 50%;
        bottom: 24px;
        margin-left: -167.5px;
    }
}
