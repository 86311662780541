
.group-list {
  .item {
    margin: 12px 15px 0 15px;
    background: #ffffff;
    border-radius: 8px;
    padding: 12px;
    .hd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .lt {
        width: 107px;
        height: 76px;
        background: url(../../assets/image/home/bg-zw.png) center/100%,
          100% no-repeat;
        border-radius: 2px;
        overflow: hidden;
        .lt-img {
          width: 100%;
          height: 100%;
        }
      }
      .rt {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 12px;
        flex: 1;
        min-height: 76px;
        .bt {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .num {
            display: flex;
            align-items: flex-end;
          }
          .join-progress {
            padding: 2px 5px;
            font-size: 11px;
            color: #fc6c21;
            line-height: 20px;
            border-radius: 4px;
            background: rgba(252, 108, 33, 0.1);
            span {
              color: #999999;
            }
          }
          .join-progerror {
            padding: 2px 5px;
            font-size: 11px;
            color: #666666;
            line-height: 20px;
            border-radius: 4px;
            background: #ececec;
          }
        }
      }
    }
    .bt {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 15px;
      .share {
        width: 60px;
        height: 24px;
        background: linear-gradient(137deg, #ff9d5b 0%, #fc6c21 100%);
        box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
        border-radius: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-share {
          display: block;
          width: 13px;
          height: 13px;
          background: url(../../assets/image/group/icon-share.png) center/100%,
            100% no-repeat;
          margin-right: 1px;
        }
      }
      .learn-now {
        width: 72px;
        box-sizing: border-box;
        border-radius: 12px;
        border: 1px solid #fc6c21;
        text-align: center;
        line-height: 24px;
        font-size: 13px;
        color: #fc6c21;
      }
    }
  }
  /deep/ .van-cell {
    padding: 0 !important;
    background: none !important;
  }
}
